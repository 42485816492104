// INITIATE TYPE SIZES

h1,
h2,
h3,
h4,
h5,
h6,
small {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}


.small {
  font-size: var(--text-xxs);
  line-height: var(--lh-xxs);
}



.credits {
  display: grid;
  grid-template-columns: repeat(var(--legal), 1fr);
  font-size: var(--text);
  line-height: var(--leading);
  @include padding(1);
}

.legal {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(var(--legal), 1fr);
  font-size: var(--fs);
  line-height: var(--leading);
  @include padding(1);
  color: var(--bg-color-nth);
  transition: all .1s ease-in;
}

.legal a {
  color: var(--bg-color-nth);
  text-decoration: underline;
}

.legal:hover {
  color: #000;
} 

.legal {
  p:not(:last-child) {
    margin-bottom: var(--lh);
  }
}


.h6 {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
}

.h5 {
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

.h4 {
  font-size: var(--fs-md);
  line-height: var(--lh-md);
}

.h3 {
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
}

.h2 {
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
}

.h1 {
  font-size: var(--fs-xxl);
  line-height: var(--lh-xxl);
}

// __________________________________________________________ //

// INITIATE LINE HEIGHT

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.small {
  font-weight: normal;
}


@media (max-width: 1000px) { 


.paragraph__container {
  grid-template-columns: 1fr;
}

}