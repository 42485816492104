body,
html {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  scroll-behavior: smooth;
}

.bodyOverflow {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.bodyOverflow .list__container {
  overflow-y: scroll;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  margin-top: 29.2px;
  -ms-scroll-snap-points-y: repeat(100%);
  scroll-snap-points-y: repeat(100%);
  -ms-scroll-snap-destination: 0 0;
      scroll-snap-destination: 0 0;
  will-change: scroll-position;
}

/*
.bodyOverflow .list__container::-webkit-scrollbar{
  display: inherit;
  background-color:transparent;
  width:16px
}
.bodyOverflow .list__container::-webkit-scrollbar-track {
  background-color:#fff
}
.bodyOverflow .list__container::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border: 4px solid #fff
}
*/

.scrollsnap {
  position: inherit;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  scroll-snap-align: start;
  scroll-snap-stop: always;

  background-color: #fff;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translateZ(0);
}


@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
