@-webkit-keyframes fadeIn {
    0% {
        opacity: 1;
    }

    30% {
        opacity: .25;
    }

    50% {
        opacity: .25;
    }

    100% {
        opacity: 1;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 1;
    }

    30% {
        opacity: .25;
    }

    50% {
        opacity: .25;
    }

    100% {
        opacity: 1;
    }
}

.list__container+.grid-container {
    margin-top: -29px;
    position: relative;
    z-index: 99;
}

.grid-container {
    display: grid;
    grid-gap: var(--grid-gap);
    @include padding(1);
    margin-top: 29.2px;
}


.filtered-container {
    display: grid;
    grid-gap: var(--grid-gap);
    @include padding(1);
}

.grid-container,
.filtered-container {
    grid-template-columns: repeat(auto-fill, 232px);
    width: 100vw;
}

.grid-container figure,
.filtered-container figure {
    padding-bottom: 0 !important;
}


.grid-container figure:hover,
.filtered-container figure:hover {
    opacity: .3;
}

.grid-container figure,
.filtered-container figure {
    background-color: #fff;
}

.list__category {
    grid-column: var(--list-category);
}

.list__title {
    grid-column: var(--list-title);
}

.list__title__ex {
    grid-column: var(--list-title);
}

.list__title__full {
    grid-column: var(--list-title-full);
}

.list__dimensions {
    display: var(--list-toggle);
    grid-column: var(--list-dimensions);
}

.list__date {
    grid-column: 1/2;
}

.item__title {
    grid-column: var(--item-title);
}

.item__commande {
    grid-column: var(--item-title);
    white-space: nowrap;
}

.item__color {
    grid-column: var(--item-color);
}

.item__year {
    grid-column: var(--item-year);
}

.fp-scrollable {
    height: auto !important;
}

@include mq(xs) {

    .grid-container,
    .filtered-container {
        grid-template-columns: 1fr 1fr;
    }



}

@include mq(sm) {

    .grid-container,
    .filtered-container {
        grid-template-columns: repeat(auto-fill, 232px);
    }
}