* {
  box-sizing: border-box;
  line-height: 1.2;
}

html {
  scroll-behavior: smooth;
  /* scroll-padding-top: 29.2px !important; */
}

body+div[is-visible] {
  display: none !important;
}

body,
html,
#container {
  max-width: 100vw;
  line-height: 1.2;
  max-width: 100%;
}

img {
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

img[src=""],
img:not([src]) {
  visibility: hidden;
}

button:focus {
  outline: none;
  border: 0px solid #000;
  box-shadow: 0px 0px 0px #000;
}

input:focus {
  outline: none;
  border: 0px solid #000;
  box-shadow: 0px 0px 5px #000;
}


:focus {
  outline: none;
}

:focus-visible {
  outline: none;
}

.lazyvideo {
  width: 1920px;
  height: 1080px;
}

.iScrollIndicator {
  display: none !important;
}

.inload {
  cursor: wait;
}


header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include padding(1);
  background: var(--bg-color-nth);
  z-index: 99999;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

header:hover {
  background-color: #fff !important;
}

header h1 {
  width: 95%;
}

header h1 a {
  width: 100%;
  display: block;
}


.wrapper {
  position: relative;
  height: 100%;
}

.container {
  padding-top: calc(4 * var(--base-space));
}


.filtered {
  display: none;
}

.filtered:first-child {
  display: block;
}



.list__container {
  background-color: #fff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  padding-bottom: 29.2px;
  /*margin-top: -29.2px; */
}

.list__container::before {
  content: '';
  display: block;
  height: 29.2px;
  margin-top: -29.2px;
  visibility: hidden;
}


.filtered {
  padding-bottom: 0;
}

.panel {
  opacity: 1;
}

.list__container section {
  border: none;
}

.posters,
.exhibitionspage,
.index {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0px;
}

.exh {
  overflow: hidden;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
}

.exh section {
  position: -webkit-sticky;
  position: sticky;
  top: calc(4 * var(--base-space));
  overflow: hidden;
}

.list__container figure {
  padding: 0 !important;
}


.list__strates {
  background-color: #ffffff;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(var(--item-grid), 1fr);
  grid-gap: var(--grid-gap);
  grid-auto-rows: 1fr;
  width: 100%;
  max-width: 100%;
  @include padding(1);
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  margin-bottom: var(--grid-gap);
  aspect-ratio: 88 / 43;
}


.section__img {
  padding: 0;
  margin: 0;
  display: var(--display-toggle);
  width: var(--section-img);
  grid-column: 1/5;
  cursor: default;
  position: relative;
  height: var(--posterheight);
  aspect-ratio: 29 / 43;
  background-color: #fff;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-repeat: no-repeat;
}


.section__swiper {
  width: 100%;
  overflow: hidden;
  grid-column: var(--grid-swiper);
}

/*
.exhibitions-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}
*/

section {
  height: auto;
}

.sect {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

/* BURGER */

#menu ul li {
  @include padding(1);
  display: flex;
  background: var(--bg-color);
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#menu ul li a {
  width: 100%;
}

#menu ul li:nth-child(2n) {
  background: var(--bg-color-nth);
}

.array__item {
  @include padding(1);
  background: var(--bg-color-nth);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99;
  background-color: inherit;
  width: 100%;
}


.array__item a span {
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.list__item {
  @include padding(1);
  background: var(--bg-color-nth);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99;
}

/* Blink*/



.blink {
  opacity: 1 !important;
  background-color: #fff !important;
}


.headerblink {
  background-color: var(--bg-color);
}


.headerblink:hover .hamburger-inner,
.headerblink:hover .hamburger-inner::before,
.headerblink:hover .hamburger-inner::after {
  background-color: #000 !important;
}



.items__active {
  background-color: #fff !important;
}

.items__active a {
  color: var(--bg-color) !important;
}


/*
.list__item:hover, .list__item:hover a  {
  background: #fff!important;
  color: #000!important; 
} */


/*
.list__item.blink:before {
  content:'';
  width: 100%;
  height: 100%;
  background-color: #fff!important;
  z-index: 99;
}

*/


.list__item span {
  white-space: nowrap;
}

.list__noitem {
  @include padding(1);
  background-color: var(--bg-color);
  z-index: 99;
  width: 100%;
}

.annonce {
  display: grid;
  grid-template-columns: repeat(var(--item-grid), 1fr);
}

.annonce span {
  grid-column: 2/13;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.posters .list__item {
  background: var(--bg-color);
  width: 100%;
}


.index .list__item:nth-child(2n) {
  background: var(--bg-color);
}

.list__item a {
  display: grid;
  grid-template-columns: repeat(var(--item-grid), 1fr);
  grid-gap: var(--grid-gap);
}

.error a {
  display: inline-block;
  text-decoration: none;
  width: 100%;
}

.error {
  background-color: var(--bg-color);
  margin-top: 14.5px;
}

.error:hover {
  background-color: #fff;
}

.arraycommande {
  width: 100%;
  cursor: auto !important;
}

.array__item,
.array__item a {
  cursor: auto !important;
}

.array__item a {
  display: grid;
  grid-template-columns: repeat(var(--item-grid), 1fr);
  grid-gap: var(--grid-gap);
}

.index .list__item {
  @include padding(1);
  line-height: 1.2;
}

.year {
  background-color: var(--category-color);
}

.year:hover {
  background-color: var(--category-color) !important;
}


.list__item li,
.list__item span {
  text-decoration: none;
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Exhibition

.exhibitions a li,
.publications a li {
  color: #fff;
}

.exhibition__title {
  background-color: #000;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  z-index: 9990;
}

.exhibition__title a {
  color: #fff;
}

#process__container {
  overflow: hidden;
  width: 100%;
  @include padding(1);
  height: calc(var(--vh, 1vh) * 100 - 60px);
}

.process__title {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

.process__title:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.exhibition__container {
  overflow: hidden;
  width: 100%;
  @include padding(1);
  height: calc(var(--vh, 1vh) * 100 - 60px);
}

.exhibition__title:hover {
  background-color: #000;
  color: #fff !important;
}

.process {
  background-color: var(--process-color);
}

// Pages

.processpage {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
}

.processpage, .filtered {
  margin-top: 29.2px;
}

.process-flck {
  @include padding(1);
  height: 95%;
}

#menu {
  transition: transform .5s;
  position: fixed;
  width: var(--menu-width);
  transform: translateY(-300px);
  top: 0;
  right: 0;
  z-index: 999;

}

#menu.show-menu {
  position: fixed;
  transform: translateY(0px);
  top: 0;
  z-index: 9999;
}

/* Container reveal FX */

.list__container[data-fx="2"] a::after,
a.content__text-link[data-fx="2"]::after {
  content: '';
  z-index: -1;
  width: 100%;
  bottom: 0.1rem;
  left: 0;
  position: absolute;
  height: 2px;
  background: transparent;
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 0%;
  transition: transform 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000);
}

a.content__text-link[data-fx="2"]::after {
  bottom: 0;
}

.list__container[data-fx="2"] a:hover::after,
a.content__text-link[data-fx="2"]:hover::after {
  transform: scale3d(1, 1, 1);
}

.hover-reveal {
  position: fixed;
  width: 116px * 2;
  height: 172px * 2;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 9999;
  transform: translate3d(0) !important;
  -o-object-fit: contain;
  object-fit: contain;
}

.hover-revealt {
  position: fixed;
  width: 116px * 2;
  height: 172px * 2;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 999999;
  border: none;
}

.hover-reveal__inner,
.hover-reveal__img {
  width: 100%;
  height: 100%;
  position: relative;
}

.hover-reveal__deco {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #181314;
}

.hover-reveal__img {
  background-size: contain;
  background-position: 50% 50%;
}




// SWUP JS

.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;

}

.swup-progress-bar {
  height: 2px;
  background-color: #fff;
  mix-blend-mode: difference;
  z-index: 9999999;
}

// Options

/*

.reverted {
  display: inline-block;
  transform: rotate(180deg);
  text-align: right;
  padding-top: 1px;
  margin-top: -1px;
}
*/

// Error

.error {
  text-decoration: underline;
}


#about {
  padding-bottom: 0;
  margin-top: 29.2px;
}


@include mq(xs) {

  .hide-on-mobile {
    display: block !important;
    width: 100% !important;
  }

  .list__dimensions {
    opacity: 0;
  }

  .exhibition__container {
    height: calc(100% - 60px);
  }


  .list__strates,
  .swiper {
    aspect-ratio: auto;
  }


}


@include mq(sm) {

  .hide-on-mobile {
    display: block !important;
    width: 100% !important;
  }

  .list__dimensions {
    opacity: 0;
  }

  .exhibition__container {
    height: calc(100% - 60px);
  }



}


@include mq(md) {

  .hide-on-mobile {
    display: none !important;
    width: 0px !important;
  }

  .list__dimensions {
    opacity: 1;
  }


  .exhibition__container {
    height: calc(var(--vh, 1vh) * 100 - 60px);
  }

  .list__strates {
    aspect-ratio: 88 / 43;
    height: var(--posterheight);
  }

  .swiper {
    aspect-ratio: 58 / 43;
  }


}

@include mq(xxl) {

  .hide-on-mobile {
    display: none !important;
    width: 0px !important;
  }

  .list__dimensions {
    opacity: 1;
  }

  .section__swiper {
    height: 100%;
  }

  .exhibition__container {
    height: calc(var(--vh, 1vh) * 100 - 60px);
  }

}


