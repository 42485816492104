// BORDER-BOX, RESET

html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

* {
  margin: 0;
  font-feature-settings: "kern" 1;
  -webkit-font-kerning: normal;
  font-kerning: normal;

  @if $font-smoothing-moz==true {
    -moz-osx-font-smoothing: grayscale;
  }

  @if $font-smoothing-webkit==true {
    -webkit-font-smoothing: antialiased;
  }
}

body,
html {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

// __________________________________________________________ //

// TYPE SIZE

html,
body {
  @extend .font-#{$main-font};
  font-size: var(--text);
  line-height: var(--lh);
  color: var(--color-text);
  background-color: var(--color-bg);
}

html {
  background: var(--color-bg);
}

a {
  color: var(--color-link);
  text-decoration: none;
  cursor: pointer;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

li {
  list-style: none;
}

ol {
  list-style-position: inside;
}

img {
  width: 100%;
  height: auto;
  color: rgba(0, 0, 0, 0) !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

img::-moz-selection {
  background: transparent;
}

img::selection {
  background: transparent;
}

strong,
b {
  font-weight: normal;
}


::-moz-selection {
  background-color: var(--bg-color);
}


::selection {
  background-color: var(--bg-color);
}