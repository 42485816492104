.paragraph__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include padding(1);
    grid-gap: calc(1 * var(--space));
    background-color: var(--bg-color);
    z-index: 99;
    width: 100%;
}

.paragraph__header p{
    padding-left: calc(1 * var(--base-space));
    padding-right: calc(1 * var(--base-space));
}


.paragraph code {
    display: flex;
    font-style: italic;
    font-family: inherit;
}

.paragraph__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(1 * var(--space));
    padding-bottom: calc(2 * var(--space));
    cursor: default;
  }

  #about a:hover {
    color: var(--bg-color-nth);
  }

  .paragraph__container em {
      font-style: italic;
  }
  
  .paragraph, .paragraph p {
    font-size: var(--text-md);
    line-height: var(--leading-md);
    @include padding(1);
  }
  
  
  .en__paragraph {
    font-size: var(--text-md);
    line-height: var(--leading-md);
    @include padding(1);
    color: #000;
  }
  
  .en__paragraph, .en__paragraph p {
    font-size: var(--text-md);
    line-height: var(--leading-md);
    @include padding(1);
  }
  

  .paragraph__xs{
    @include padding(1);
  }




  @include mq(xs) {

    .paragraph__container {
      display: grid;
      grid-template-columns: 1fr;
    }

    .en__paragraph {
      color: #999999;
    }

  }

  @include mq(md) {

    .paragraph__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: calc(1 * var(--space));
    }

    .en__paragraph {
      color: #000;
    }

  }