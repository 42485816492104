/* LAZYLOAD */

figure { width: 100%; background-color: var(--bg-color); }
img[data-sizes="auto"] { 
  display: block; 
  width: 100%;
  height: auto; }

.blur-up {
  opacity: 0;
  transition: opacity 800ms, -webkit-opacity 800ms;
  transition-delay: .5s;
}

.blur-up.lazyloaded{
  opacity: 1;
}

.js .project-pagination {
  display: none;
}

.no-js .load-more {
  display: none;
}

.next {
  text-align: right;
}

.menu__item-link {
  position: absolute;
  z-index: 9999;
  width: 100vw;
  text-align: center;
  @include padding(1);
  animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}