// CONFIGURATE DOCUMENT
$fontsize: (
  xxs: (
    desktop: (
      font-size: 0.5rem,
      line-height: 0.625rem,
    ),
    mobile: (
      font-size: 0.5rem,
      line-height: 0.625rem,
    ),
  ),
  xs: (
    desktop: (
      font-size: 0.75rem,
      line-height: 0.9375rem,
    ),
    mobile: (
      font-size: 0.75rem,
      line-height: 0.9375rem,
    ),
  ),
  sm: (
    desktop: (
      font-size: 1rem,
      line-height: 1.25rem,
    ),
    mobile: (
      font-size: 1rem,
      line-height: 1.25rem,
    ),
  ),
  md: (
    desktop: (
      font-size: 1.5rem,
      line-height: 1.875rem,
    ),
    mobile: (
      font-size: 1.5rem,
      line-height: 1.875rem,
    ),
  ),
  lg: (
    desktop: (
      font-size: 2rem,
      line-height: 2.5rem,
    ),
    mobile: (
      font-size: 2rem,
      line-height: 2.5rem,
    ),
  ),
  xl: (
    desktop: (
      font-size: 5rem,
      line-height: 6.25rem,
    ),
    mobile: (
      font-size: 5rem,
      line-height: 6.25rem,
    ),
  ),
  xxl: (
    desktop: (
      font-size: 10rem,
      line-height: 12.5rem,
    ),
    mobile: (
      font-size: 10rem,
      line-height: 12.5rem,
    ),
  ),
);

$margin: (
  xxs: 0.1,
  xs: 0.5,
  sm: 1,
  md: 2,
  lg: 4,
  xl: 8,
  xxl: 16,
);

$space-d: (
  xxs: 0.1,
  xs: 0.5,
  sm: 1,
  md: 2,
  lg: 4,
  xl: 8,
  xxl: 16,
);

$space-m: (
  xxs: 0.1,
  xs: 0.5,
  sm: 1,
  md: 2,
  lg: 4,
  xl: 8,
  xxl: 16,
);

:root {
  --columns: 12;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$font-smoothing-moz: true;
$font-smoothing-webkit: true;

$main-font: 1;
$fonts: (
  1: (
    name: Helvetica,
    fallback: "Helvetica Neue, Helvetica, Arial, sans-serif",
    url: "",
    weight: normal,
    style: normal,
    display: auto,
  ),
  2: (
    name: Times,
    fallback: "Times New Roman, Times, serif",
    url: "",
    weight: normal,
    style: normal,
    display: auto,
  ),
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 1000px,
  lg: 1200px,
  xl: 1500px,
  xxl: 1800px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.3s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
