@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpoints) {
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }
  @media screen and (min-width: #{$mq-breakpoint}) {
    @content;
  }
}


@function getFontSize($size, $type: desktop) {
  @return map-get(map-get(map-get($fontsize, $size), $type), font-size);
}

@function getLineHeight($size, $type: desktop) {
  @return map-get(map-get(map-get($fontsize, $size), $type), line-height);
}
