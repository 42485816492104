:root {
  --base-space: 5px;
  --grid: 6;
  --legal: 3;
  --item-grid: 12;
  --grid-gap: 5px;

  --margin: var(--base-space);
  --margin-xxs: calc(var(--base-space) * #{map-get($margin, xxs)});
  --margin-xs: calc(var(--base-space) * #{map-get($margin, xs)});
  --margin-sm: calc(var(--base-space) * #{map-get($margin, sm)});
  --margin-md: calc(var(--base-space) * #{map-get($margin, md)});
  --margin-lg: calc(var(--base-space) * #{map-get($margin, lg)});
  --margin-xl: calc(var(--base-space) * #{map-get($margin, xl)});
  --margin-xxl: calc(var(--base-space) * #{map-get($margin, xxl)});

  --text: #{getFontSize(sm, mobile)};
  --text-xxs: #{getFontSize(xxs, mobile)};
  --text-xs: #{getFontSize(xs, mobile)};
  --text-sm: #{getFontSize(sm, mobile)};
  --text-md: #{getFontSize(md, mobile)};
  --text-lg: #{getFontSize(lg, mobile)};
  --text-xl: #{getFontSize(xl, mobile)};
  --text-xxl: #{getFontSize(xxl, mobile)};

  --leading: #{getLineHeight(sm, mobile)};
  --leading-xxs: #{getLineHeight(xxs, mobile)};
  --leading-xs: #{getLineHeight(xs, mobile)};
  --leading-sm: #{getLineHeight(sm, mobile)};
  --leading-md: #{getLineHeight(md, mobile)};
  --leading-lg: #{getLineHeight(lg, mobile)};
  --leading-xl: #{getLineHeight(xl, mobile)};
  --leading-xxl: #{getLineHeight(xxl, mobile)};

  --space: #{getLineHeight(sm, mobile)};
  --space-xxs: #{getLineHeight(sm, mobile) * map-get($space-m, xxs)};
  --space-xs: #{getLineHeight(sm, mobile) * map-get($space-m, xs)};
  --space-sm: #{getLineHeight(sm, mobile) * map-get($space-m, sm)};
  --space-md: #{getLineHeight(sm, mobile) * map-get($space-m, md)};
  --space-lg: #{getLineHeight(sm, mobile) * map-get($space-m, lg)};
  --space-xl: #{getLineHeight(sm, mobile) * map-get($space-m, xl)};
  --space-xxl: #{getLineHeight(sm, mobile) * map-get($space-m, xxl)};

  --header-height: calc(var(--lh-xl) + var(--margin-sm) * 3);

  // Colors
  --bg-color: #e9e9e9;
  --bg-color-nth: #d1cfcf;
  --category-color: #fff;
  --hover-color: #ffffff;
  --exhibitions-color: cyan;
  --process-color: green;

  // Menu
  --menu-width: 20vw;

  // Show / Hide mobile
  --display-toggle: flex;
  --section-img: 100%;
  --section-slick: 69%;

  --grid-tcolumns: 33% 66%;
  --grid-swiper: 5/13;

  --list-category: 2/3;
  --list-title: 3/8;
  --list-dimensions: 9/13;


  --list-toggle: block;

  --item-title: 1/3;
  --item-color: 5/6;
  --item-year: 9/13;

  --vh: 1vh;
  --posterheight: 100%;


  @include mq(xs) {
    --grid: 2;
    --grid-gap: 5px;
    --menu-width: 50vw;
    --item-grid: 6;

    --display-toggle: none;
    --section-slick: 100%;
    --grid-tcolumns: 100%;
    --grid-swiper: 1/13;

    --item-title: 1/4;
    --item-color: 4/6;
    --item-year: 6/6;

    --list-category: 2/4;
    --list-title: 4/8;
    --list-title-full: 4/7;
    --list-dimensions: 5/7;
    --list-toggle: none;

    --legal: 1;

    --toggle-carousel: block;

  }

  @include mq(sm) {
    --base-space: 5px;
    --grid: 3;
    --item-grid: 6;
    --menu-width: 30vw;
    --grid-swiper: 1/12;

    --legal: 2;

    --list-category: 2/3;
    --list-title: 3/7;
    --list-title-full: 3/7;
    --list-dimensions: 5/7;
    --list-toggle: none;

    --text: #{getFontSize(sm, desktop)};
    --text-xxs: #{getFontSize(xxs, desktop)};
    --text-xs: #{getFontSize(xs, desktop)};
    --text-sm: #{getFontSize(sm, desktop)};
    --text-md: #{getFontSize(md, desktop)};
    --text-lg: #{getFontSize(lg, desktop)};
    --text-xl: #{getFontSize(xl, desktop)};
    --text-xxl: #{getFontSize(xxl, desktop)};

    --leading: #{getLineHeight(sm, desktop)};
    --leading-xxs: #{getLineHeight(xxs, desktop)};
    --leading-xs: #{getLineHeight(xs, desktop)};
    --leading-sm: #{getLineHeight(sm, desktop)};
    --leading-md: #{getLineHeight(md, desktop)};
    --leading-lg: #{getLineHeight(lg, desktop)};
    --leading-xl: #{getLineHeight(xl, desktop)};
    --leading-xxl: #{getLineHeight(xxl, desktop)};

    --space: #{getLineHeight(sm, desktop)};
    --space-xxs: #{getLineHeight(sm, desktop) * map-get($space-d, xxs)};
    --space-xs: #{getLineHeight(sm, desktop) * map-get($space-d, xs)};
    --space-sm: #{getLineHeight(sm, desktop) * map-get($space-d, sm)};
    --space-md: #{getLineHeight(sm, desktop) * map-get($space-d, md)};
    --space-lg: #{getLineHeight(sm, desktop) * map-get($space-d, lg)};
    --space-xl: #{getLineHeight(sm, desktop) * map-get($space-d, xl)};
    --space-xxl: #{getLineHeight(sm, desktop) * map-get($space-d, xxl)};

    --header-height: 0;
    --display-toggle: none;
    --section-slick: 100%;
    --grid-tcolumns: 100%;

    --item-title: 1/4;
    --item-color: 4/5;
    --item-year: 6/7;

    --toggle-carousel: block;

  }

  /*
  xs: 0px,
  sm: 576px,
  md: 1000px,
  lg: 1200px,
  xl: 1500px,
  xxl: 1800px,
*/
  @include mq(md) {
    --grid: 5;
    --item-grid: 12;
    --grid-gap: 5px;
    --menu-width: 20vw;
    --display-toggle: flex;
    --section-slick: 69%;
    --grid-tcolumns: 33% 66%;
    --grid-swiper: 5/13;

    --list-category: 2/4;
    --list-title: 4/7;
    --list-title-full: 4/13;
    --list-dimensions: 8/13;
    --item-year: 8/13;

    --item-title: 1/4;
    --item-color: 5/8;
    --item-year: 9/13;


    --list-toggle: block;


    --legal: 3;

    --toggle-carousel: none;

  }

  @include mq(xxl) {
    --grid: 10;
    --item-grid: 12;
    --grid-gap: 5px;
    --display-toggle: block;
    --section-slick: 69%;
    --grid-tcolumns: 33% 66%;
    --grid-swiper: 5/13;

    --list-category: 2/3;
    --list-title: 3/7;
    --list-title-full: 3/13;
    --list-dimensions: 8/13;

    --item-title: 1/3;
    --item-color: 5/8;
    --item-year: 9/13;

    --list-toggle: block;

    --legal: 3;

    --toggle-carousel: none;
  }

}